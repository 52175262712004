import { DotLottiePlayer, PlayerEvents } from "@dotlottie/react-player";
import styles from "./FloorMobile.module.css";
import { useCallback, useEffect, useRef } from "react";

export default function FloorMobile({
  name,
  onActivePortfolio,
  currentPortfolio,
}) {
  const containerRef = useRef(null);
  const floorLottieRef = useRef(null);
  const doorLottieRef = useRef(null);

  const isInView = useRef(false);
  const floorIsReady = useRef(false);
  const doorIsReady = useRef(false);

  useEffect(() => {
    if (currentPortfolio) {
      floorLottieRef.current?.stop();
      if (doorLottieRef.current.direction === 1) {
        doorLottieRef.current?.setDirection(-1);
        doorLottieRef.current?.play();
      }
    } else {
      if (floorIsReady.current && doorIsReady.current) {
        floorLottieRef.current?.play();
        doorLottieRef.current?.setDirection(1);
        doorLottieRef.current?.play();
      }
    }
  }, [currentPortfolio]);

  const checkInView = useCallback(() => {
    if (floorIsReady.current && doorIsReady.current) {
      const rect = containerRef.current.getBoundingClientRect();
      isInView.current = rect.top < window.innerHeight && rect.bottom >= 0;
      if (isInView.current && !currentPortfolio) {
        floorLottieRef.current?.play();
        doorLottieRef.current?.play();
      } else {
        floorLottieRef.current?.stop();
        doorLottieRef.current?.stop();
      }
    }
  }, [doorIsReady, floorIsReady, isInView, currentPortfolio]);

  useEffect(() => {
    document.addEventListener("scroll", checkInView);
    return () => {
      document.removeEventListener("scroll", checkInView);
    };
  }, [checkInView]);

  return (
    <div ref={containerRef} className={styles[`${name}_floor_mb`]}>
      <img
        src={`./pics/${name}-floor-bg-mb.png`}
        alt="floor-bg"
        className={styles.floor_bg_mb}
      />
      <DotLottiePlayer
        ref={floorLottieRef}
        className={styles.floor_lottie_mb}
        src={`./lotties/${name}-floor-mb.lottie`}
        background="transparent"
        speed="1"
        loop
        onEvent={(event) => {
          // Listen for player ready
          if (event === PlayerEvents.Ready) {
            floorIsReady.current = true;
          }
        }}
      ></DotLottiePlayer>

      <DotLottiePlayer
        ref={doorLottieRef}
        className={styles.door_lottie_mb}
        src={`./lotties/${name}-door-mb.lottie`}
        background="transparent"
        speed="1"
        onEvent={(event) => {
          // Listen for player ready
          if (event === PlayerEvents.Ready) {
            doorIsReady.current = true;
          }
        }}
      ></DotLottiePlayer>
      <div
        className={styles[`${name}_hidden_div_mb`]}
        onClick={() => {
          onActivePortfolio(name);
        }}
      ></div>
      <div className={styles[`${name}_fan`]}>
        <img
          className={styles.rotate_fan}
          src={`./pics/${name}-fan.svg`}
          alt="AC Fan"
        />
      </div>
    </div>
  );
}
