import styles from "./FooterMobile.module.css";

export default function FooterMobile({ onActivePopup }) {
  return (
    <div className={styles.footer_mb} id="footer-section">
      <img
        src="./pics/footer-mobile.png"
        alt="footer background"
        className={styles.footer_bg_mb}
      />
      <ContactFormMobile />
      <FooterButtonsMobile onActivePopup={onActivePopup} />
      <SocialButtonsMobile />
    </div>
  );
}

function ContactFormMobile() {
  return (
    <form
      name="contact"
      method="POST"
      id="contact_mb"
      className={styles.contact_form_mb}
      data-netlify="true"
    >
      <input type="hidden" name="form-name" value="contact" />
      <h1 className={styles.contact_heading_mb}>
        Interested in working with us?
      </h1>
      <input
        name="name"
        type="text"
        placeholder="Name"
        className={styles.neon_input_mb}
        id="name_mb"
        required
      />
      <input
        name="email"
        type="email"
        placeholder="Email"
        className={styles.neon_input_mb}
        id="email_mb"
        required
      />
      <textarea
        name="message"
        placeholder="Your Query"
        rows="4"
        className={`${styles.neon_input_mb} ${styles.textarea_mb}`}
        id="query_mb"
        required
      ></textarea>

      <button
        type="submit"
        className={styles.neon_button_mb}
        id="submit_button_mb"
      >
        Submit
      </button>
    </form>
  );
}

function FooterButtonsMobile({ onActivePopup }) {
  return (
    <>
      <div className={styles.footer_scroll_container_mb}>
        <img
          src="./pics/footer-scroll.png"
          alt=""
          className={styles.scroll_text_mb}
        />
        <a className={styles.scroll_link} href="#mobile_app">
          Scroll Up
        </a>
      </div>
      <div className={styles.footer_scroll_arrow_left_mb}>
        <img
          src="./pics/footer-scroll-arrow.svg"
          alt=""
          className={styles.scroll_arrow_left}
        />
      </div>
      <div className={styles.footer_scroll_arrow_right_mb}>
        <img
          src="./pics/footer-scroll-arrow.svg"
          alt=""
          className={styles.scroll_arrow_right}
        />
      </div>
      <div className={styles.footer_buttons_container_mb}>
        <div
          className={styles.faq_mb}
          onClick={() => {
            onActivePopup("faq");
          }}
        >
          FAQ
        </div>
        <div
          className={styles.privacy_mb}
          onClick={() => {
            onActivePopup("privacy");
          }}
        >
          Privacy
        </div>
        <div
          className={styles.terms_mb}
          onClick={() => {
            onActivePopup("terms");
          }}
        >
          Terms
        </div>
        <div className={styles.mtt_mb}>
          <a href="/about">Meet The Team</a>
        </div>
      </div>
    </>
  );
}

function SocialButtonsMobile() {
  return (
    <>
      <div
        className={`${styles.social_button_mb} ${styles.upwork_mb}`}
        onClick={() => {
          window.open("https://www.upwork.com/freelancers/arkab", "_blank");
        }}
      >
        <img
          src="./pics/upwork.png"
          alt=""
          className={styles.social_normal_mb}
        />
        <img
          src="./pics/upwork-glow.png"
          alt=""
          className={styles.social_glow_mb}
        />
      </div>
      <div
        className={`${styles.social_button_mb} ${styles.lottie_mb}`}
        onClick={() => {
          window.open("https://lottiefiles.com/091qv1joz1scc5bf", "_blank");
        }}
      >
        <img
          src="./pics/lottie.png"
          alt=""
          className={styles.social_normal_mb}
        />
        <img
          src="./pics/lottie-glow.png"
          alt=""
          className={styles.social_glow_mb}
        />
      </div>
      <div
        className={`${styles.social_button_mb} ${styles.youtube_mb}`}
        onClick={() => {
          window.open("https://www.youtube.com/@ADayInTime99", "_blank");
        }}
      >
        <img
          src="./pics/youtube.png"
          alt=""
          className={styles.social_normal_mb}
        />
        <img
          src="./pics/youtube-glow.png"
          alt=""
          className={styles.social_glow_mb}
        />
      </div>
      <div
        className={`${styles.social_button_mb} ${styles.behance_mb}`}
        onClick={() => {
          window.open("https://www.behance.net/adayintime2020", "_blank");
        }}
      >
        <img
          src="./pics/behance.png"
          alt=""
          className={styles.social_normal_mb}
        />
        <img
          src="./pics/behance-glow.png"
          alt=""
          className={styles.social_glow_mb}
        />
      </div>
      <div
        className={`${styles.social_button_mb} ${styles.insta_mb}`}
        onClick={() => {
          window.open("https://www.instagram.com/aristocat.studio/", "_blank");
        }}
      >
        <img
          src="./pics/insta.png"
          alt=""
          className={styles.social_normal_mb}
        />
        <img
          src="./pics/insta-glow.png"
          alt=""
          className={styles.social_glow_mb}
        />
      </div>
      <div
        className={`${styles.social_button_mb} ${styles.linkedin_mb}`}
        onClick={() => {
          window.open(
            "https://www.linkedin.com/in/aristocat-studio-now/",
            "_blank"
          );
        }}
      >
        <img
          src="./pics/linkedin.png"
          alt=""
          className={styles.social_normal_mb}
        />
        <img
          src="./pics/linkedin-glow.png"
          alt=""
          className={styles.social_glow_mb}
        />
      </div>
    </>
  );
}
