import { useState, useEffect } from "react";
import styles from "./LoadingScreen.module.css";

export default function LoadingScreen({ onLoading, onRoofIsReady }) {
  const [welcomeClicked, setWelcomeClicked] = useState(false);
  const [logoLoaded, setLogoLoaded] = useState(false);

  useEffect(() => {
    document.body.classList.add("no-scroll");
  }, []);

  return (
    <div
      className={`${styles.loading_container} ${
        welcomeClicked && styles.invisible
      }`}
    >
      <div className={styles.logo_container}>
        <div
          className={`${styles.img_container} ${logoLoaded && styles.loaded}`}
        >
          <img
            src="pics/loader.svg"
            alt="Aristocat Logo"
            className={`${styles.pulse_image} ${logoLoaded && styles.pulsing}`}
            onLoad={() => {
              setLogoLoaded(true);
            }}
          />
        </div>
      </div>
      <div className={`${styles.text} ${logoLoaded && styles.loaded}`}>
        In a world taken over by AI, there is one design studio still trying to
        defy the odds.
      </div>

      <div className={styles.welcome_container}>
        {(!onLoading && logoLoaded && onRoofIsReady) && (
          <div
            className={`${styles.welcome_button}`}
            onClick={() => {
              setWelcomeClicked(true);
              document.body.classList.remove("no-scroll");
            }}
          >
            Enter Aristocat Studio
          </div>
        )}
      </div>
    </div>
  );
}
