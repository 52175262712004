import { useEffect, useState, useRef } from "react";
import styles from "./PowerPlayer.module.css";
import { DotLottiePlayer, PlayerEvents } from "@dotlottie/react-player";

// https://hyros.com/

export default function PowerPlayer() {
  const [loading, setLoading] = useState(true);
  const [coverIsReady, setCoverIsReady] = useState(false);
  const [faqOpen, setFaqOpen] = useState(false);
  const coverLottie = useRef(null);

  function handleFaqOpen() {
    setFaqOpen((prev) => !prev);
  }

  function handlePlayCoverAnimation() {
    coverLottie.current?.play();
  }

  useEffect(() => {
    // Function to change the theme color
    const changeThemeColor = () => {
      // Select the theme-color meta tag
      const themeColorMeta = document.querySelector('meta[name="theme-color"]');

      // Update the content attribute to the desired color
      if (themeColorMeta) {
        themeColorMeta.setAttribute("content", "#050a2b"); // Change to your desired color
      }
    };

    // Call the function to change theme color
    changeThemeColor();

    // Cleanup function
    return () => {
      // Reset theme color if necessary
      const themeColorMeta = document.querySelector('meta[name="theme-color"]');
      if (themeColorMeta) {
        themeColorMeta.setAttribute("content", "#00064a"); // Reset to default color
      }
    };
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      setLoading(false);
    };
    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <>
      <LoadingScreen
        onLoading={loading}
        onCoverIsReady={coverIsReady}
        onPlayCoverAnimation={handlePlayCoverAnimation}
      />
      {faqOpen && <FAQModule onFaqOpen={handleFaqOpen} />}
      <div className={styles.power_container} id="power_container">
        <DotLottiePlayer
          ref={coverLottie}
          src="/lotties/pp-cover.lottie"
          background="transparent"
          speed="1"
          direction="1"
          mode="normal"
          className={styles.cover_animation}
          loop
          onEvent={(event) => {
            // Listen for player ready
            if (event === PlayerEvents.Ready) {
              setCoverIsReady(true);
            }
          }}
        ></DotLottiePlayer>

        <div className={styles.headline_section}>
          <div className={styles.headline_left_container}>
            <h2 className={styles.headline_copy_1}>
              Grow your brand on auto-pilot.
            </h2>
            <h2 className={styles.headline_copy_2}>
              Make more money. Enjoy your life.
            </h2>
            <h2 className={styles.headline_copy_3}>Be The Power Player!</h2>
          </div>
          <div className={styles.headline_right_container}>
            <div className={styles.headline_button}>
              <a href="#packages">Check Out Our Packages</a>
            </div>
            {/* <div className={styles.headline_button}>Visit Our Homepage</div> */}
          </div>
        </div>
        <div className={styles.intro_section}>
          <div className={styles.intro_copy}>
            <div className={styles.intro_copy_heading}>
              What is The Power Player by Aristocat Studio?
            </div>
            <p className={styles.intro_copy_line_1}>
              The Power Player helps entrepreneurs, marketers, coaches and
              consultants break through the noise in social media and stand out
              as an expert in their industry through cutting-edge social media
              content.
            </p>
            <p className={styles.intro_copy_line_2}>
              Why do you need this service by your side?
            </p>
            <p className={styles.intro_copy_line_3}>
              Well, imagine you get an opportunity to speak on a massive stage
              in front of THOUSANDS of people.
            </p>
            <p className={styles.intro_copy_line_4}>
              How much time and effort are you going to put into your speech,
              what you’re wearing, and your overall presentation?
            </p>
            <p className={styles.intro_copy_line_5}>
              Let me answer that question - you’ll at least spend a dozen hours.
            </p>
          </div>

          <div className={styles.intro_point_1}>
            <p>
              You’ll spend at least a dozen hours planning and preparing because
              you know getting up on stage and commanding the attention of a
              thousand people is not easy!
            </p>
            <p>
              Most importantly, your reputation is at stake. And when your
              reputation is at stake, you can’t afford to slack off or make
              mistakes.
            </p>
            <p>
              Well, social media today is that stage, and it gives you the power
              to reach not hundreds, not thousands, but millions of people every
              time you post.
            </p>
            <p>
              That’s why, to stand out from the crowd, you must show up on
              social media with the same degree of care and attention as you’d
              show up to speak on stage.
            </p>
          </div>

          <DotLottiePlayer
            src="/lotties/pp-1-social-network.lottie"
            background="transparent"
            speed="1"
            direction="1"
            mode="normal"
            className={styles.intro_lottie_1}
            loop
            autoplay
          ></DotLottiePlayer>

          <DotLottiePlayer
            src="/lotties/pp-2-dying-engagement.lottie"
            background="transparent"
            speed="1"
            direction="1"
            mode="normal"
            className={styles.intro_lottie_2}
            loop
            autoplay
          ></DotLottiePlayer>

          <div className={styles.intro_point_2}>
            <p>
              <span>But here’s the problem</span> - if you’re a busy
              entrepreneur, finding time to grow on social media could prove to
              be extremely challenging.
            </p>
            <p>
              And even if you do find the time, you’ll still need to figure out
              what works and what doesn’t on every single platform!
            </p>
          </div>

          <div className={styles.intro_point_3}>
            <p>
              Let’s say you somehow even manage to do all these things by
              yourself, what do you think will happen to the OTHER SIDE of your
              business?
            </p>
            <p>
              You know, the side that actually MAKES MONEY? Well… you know the
              answer to that question.
            </p>
          </div>
          <DotLottiePlayer
            src="/lotties/pp-3-losing-money.lottie"
            background="transparent"
            speed="1"
            direction="1"
            mode="normal"
            className={styles.intro_lottie_3}
            loop
            autoplay
          ></DotLottiePlayer>

          <div className={styles.intro_box}>
            <p>
              <span>
                You’re a thought leader, a visionary, a people person – your
                time is better spent focusing on your zone of genius and
                transforming lives.
              </span>
            </p>
            <p>
              As far as content production goes,{" "}
              <span>The Power Player by Aristocat Studio</span> is here to save
              the day and get you your first 1000 true fans.
            </p>
            <p>
              This program is all about cutting through the noise and standing
              out in your field through social media content without any of the
              overwhelm that most entrepreneurs face daily.
            </p>
            <p>
              We’re talking videos, animations, illustrations, and everything in
              between.
            </p>
            <div className={styles.bullet_points_header}>
              With The Power Player, you’ll be able to:
            </div>
            <div className={styles.bullet_points}>
              <div className={styles.bullet_point}>
                <img
                  src="/power-player/pp-checkbox.svg"
                  alt=""
                  className={styles.bullet_point_check}
                />
                Reach your ideal audience
              </div>
              <div className={styles.bullet_point}>
                <img
                  src="/power-player/pp-checkbox.svg"
                  alt=""
                  className={styles.bullet_point_check}
                />
                Get your first 1000 true fans
              </div>
              <div className={styles.bullet_point}>
                <img
                  src="/power-player/pp-checkbox.svg"
                  alt=""
                  className={styles.bullet_point_check}
                />
                Book more appointments
              </div>
              <div className={styles.bullet_point}>
                <img
                  src="/power-player/pp-checkbox.svg"
                  alt=""
                  className={styles.bullet_point_check}
                />
                Onboard more clients
              </div>
              <div className={styles.bullet_point}>
                <img
                  src="/power-player/pp-checkbox.svg"
                  alt=""
                  className={styles.bullet_point_check}
                />
                Network with your peers
              </div>
              <div className={styles.bullet_point}>
                <img
                  src="/power-player/pp-checkbox.svg"
                  alt=""
                  className={styles.bullet_point_check}
                />
                Get offers to do more interviews 
              </div>
              <div className={styles.bullet_point}>
                <img
                  src="/power-player/pp-checkbox.svg"
                  alt=""
                  className={styles.bullet_point_check}
                />
                And even live on-stage presentations
              </div>
            </div>
            <p>
              Basically, become SOMEONE who is the{" "}
              <span>go-to person in their industry.</span>
            </p>
            <p>
              Whether you’re a solopreneur, a marketer, a coach, a consultant,
              or any other type of digital entrepreneur, our program will help
              you become that person—FASTER!
            </p>
            <p>
              <span>The Power Player</span> program is a Done-With-You (DWY)
              service that’s built with one core intention in mind: To free up
              your time so that you can serve more clients, make more money, and
              live your best life.
            </p>
          </div>
        </div>
        <div className={styles.right_section}>
          <div className={styles.right_copy}>
            <div className={styles.right_copy_heading}>
              How do I know if The Power Player by Aristocat Studio is right for
              me?
            </div>
            <p>
              This program is perfect for you if you have unique ideas to share
              with the world but you either{" "}
              <span>don’t have enough time to produce content…</span>
            </p>
            <p>
              Or always{" "}
              <span>
                find yourself lost when it comes to figuring out how to deliver
                your message
              </span>{" "}
              in the best possible way.
            </p>
            <div className={styles.label}>
              You don’t have an ‘idea problem.’{" "}
              <br className={styles.mobile_line_break} /> You have an ‘execution
              problem.’
            </div>
          </div>
          <div className={styles.right_description_1}>
            <p>
              Far too often, solopreneurs, marketers, coaches, and consultants
              try to do too much, too soon. And all by themselves.
            </p>
            <p>
              Apart from bringing in your A-game and serving clients, you spend
              an UNGODLY number of hours creating social media content:
            </p>
            <div className={styles.right_bullet_points}>
              <div className={styles.bullet_point}>
                <img
                  src="/power-player/sad.svg"
                  alt=""
                  className={styles.bullet_point_check}
                />
                You write your own social media posts
              </div>
              <div className={styles.bullet_point}>
                <img
                  src="/power-player/sad.svg"
                  alt=""
                  className={styles.bullet_point_check}
                />
                Shoot your own videos at home
              </div>
              <div className={styles.bullet_point}>
                <img
                  src="/power-player/sad.svg"
                  alt=""
                  className={styles.bullet_point_check}
                />
                Take those videos and edit them in different formats
              </div>
              <div className={styles.bullet_point}>
                <img
                  src="/power-player/sad.svg"
                  alt=""
                  className={styles.bullet_point_check}
                />
                Dedicate a few hours every day to building up an audience
              </div>
              <div className={styles.bullet_point}>
                <img
                  src="/power-player/sad.svg"
                  alt=""
                  className={styles.bullet_point_check}
                />
                And then spend a few hours more responding to their comments and
                replies
              </div>
            </div>
            <p>
              And then spend a few hours more responding to their comments and
              replies And you do this ALL while juggling all the
              responsibilities of managing your business, running errands, or
              looking after your pets and kids.
            </p>
          </div>
          <DotLottiePlayer
            src="/lotties/pp-4-managing.lottie"
            background="transparent"
            speed="1"
            direction="1"
            mode="normal"
            className={styles.right_lottie_1}
            loop
            autoplay
          ></DotLottiePlayer>
          <DotLottiePlayer
            src="/lotties/pp-5-angry.lottie"
            background="transparent"
            speed="1"
            direction="1"
            mode="normal"
            className={styles.right_lottie_2}
            loop
            autoplay
          ></DotLottiePlayer>
          <div className={styles.right_description_2}>
            <p>The result?</p>
            <p>
              You either get overwhelmed and quit or end up diluting the quality
              of your work to the point where it becomes… mehh.
            </p>
            <p>
              And “mehh” content doesn’t bring you more sales. Let alone raving
              fans who would buy anything you put out into the world.{" "}
            </p>
            <p>
              In fact, it could chase away a lot of your current customers, and
              turn existing fans into haters.
            </p>
            <p>
              I mean, if you showed up to a party or a networking event wearing
              dirty or tattered clothes, wouldn’t you be frowned upon?
            </p>
            <p>
              Well, if you present yourself online with content that looks the
              same way, you’ll get the same reaction.
            </p>
          </div>
          <div className={styles.right_ending}>
            <div className={styles.label}>
              In 2024, your content is your personal brand’s biggest ambassador.
            </div>
            <p>
              With The <span>Power Player:</span>
            </p>
            <p>
              You can focus on what <span>you do best</span> – showing up as
              your authentic self and serving clients.
            </p>
            <p>
              And let us do what <span>we do best</span> – grow your brand and
              fan base on autopilot, so that you attract better clients and make
              more money while working fewer hours. Consistently!
            </p>
          </div>
        </div>
        <div className={styles.boost_section}>
          <div className={styles.boost_heading}>
            How will you boost my presence online?
          </div>
          <div className={styles.boost_container_section_1}>
            <div className={styles.boost_copy_1}>
              We’ll take over from there and handle the ENTIRE production and
              promotional process from A to Z.
            </div>
            <div className={styles.boost_bullet_points_1}>
              <div className={styles.boost_bullet_point}>
                <img
                  src="/power-player/star-inside-circle.svg"
                  alt=""
                  className={styles.boost_bullet_point_img}
                />
                <span>
                  We’ll edit your RAW footage and create captivating Reels for
                  Instagram.
                </span>
              </div>
              <div className={styles.boost_bullet_point}>
                <img
                  src="/power-player/star-inside-circle.svg"
                  alt=""
                  className={styles.boost_bullet_point_img}
                />
                <span>
                  Take your written posts from social media and turn them into
                  gorgeous carousels.
                </span>
              </div>
              <div className={styles.boost_bullet_point}>
                <img
                  src="/power-player/star-inside-circle.svg"
                  alt=""
                  className={styles.boost_bullet_point_img}
                />
                <span>
                  Turn your voice notes into jaw-dropping minimalist animations
                  for Reels.
                </span>
              </div>
              <div className={styles.boost_bullet_point}>
                <img
                  src="/power-player/star-inside-circle.svg"
                  alt=""
                  className={styles.boost_bullet_point_img}
                />
                <span>
                  Last but not least, repurpose ALL the above content for
                  different social media platforms like TikTok, Facebook,
                  LinkedIn, etc., for maximum reach.
                </span>
              </div>
            </div>
            <div className={styles.boost_before_title}>BEFORE</div>
            <div className={styles.boost_after_title}>AFTER</div>
            <VideoPlayer
              src="/power-player/boost-video-before.mp4"
              type="video/mp4"
              thumbnail="/power-player/pp-video-thumbnail-1.jpg"
            />
            <VideoPlayer
              src="/power-player/boost-video-after.mp4"
              type="video/mp4"
              thumbnail="/power-player/pp-video-thumbnail-2.jpg"
            />
          </div>
          <div className={styles.boost_container_section_2}>
            <div className={styles.boost_copy_2_container}>
              <div className={styles.boost_copy_2}>
                This will free up 3-5 hours of your time each day. GUARANTEED!
                This is as “Hands-off” as hands-off could get!
                <br />
                <br />
                Imagine WHAT ELSE you could be doing by saving 3-4 hours daily!
                You could be:
              </div>
              <div className={styles.boost_bullet_points_2}>
                <div className={styles.boost_bullet_point}>
                  <img
                    src="/power-player/happy.svg"
                    alt=""
                    className={styles.boost_bullet_point_img}
                  />
                  <span>Brainstorming your next big idea</span>
                </div>
                <div className={styles.boost_bullet_point}>
                  <img
                    src="/power-player/happy.svg"
                    alt=""
                    className={styles.boost_bullet_point_img}
                  />
                  <span>Strategizing your next big launch</span>
                </div>
                <div className={styles.boost_bullet_point}>
                  <img
                    src="/power-player/happy.svg"
                    alt=""
                    className={styles.boost_bullet_point_img}
                  />
                  <span>Making deep connections with your clients</span>
                </div>
                <div className={styles.boost_bullet_point}>
                  <img
                    src="/power-player/happy.svg"
                    alt=""
                    className={styles.boost_bullet_point_img}
                  />
                  <span>Working fewer hours every day</span>
                </div>
                <div className={styles.boost_bullet_point}>
                  <img
                    src="/power-player/happy.svg"
                    alt=""
                    className={styles.boost_bullet_point_img}
                  />
                  <span>Spending more time with your family and friends</span>
                </div>
                <div className={styles.boost_bullet_point}>
                  <img
                    src="/power-player/happy.svg"
                    alt=""
                    className={styles.boost_bullet_point_img}
                  />
                  <span>
                    Or, if you’re like me, play video games and cuddle up with
                    your pet (I have two cats)
                  </span>
                </div>
              </div>
              When you spend more time doing what you love, you not only have a
              better life but also MAKE MORE MONEY! And we all could use having
              a little more money in the bank, right?
            </div>
            <DotLottiePlayer
              src="/lotties/pp-bossman.lottie"
              background="transparent"
              speed="1"
              direction="1"
              mode="normal"
              className={styles.boost_lottie}
              loop
              autoplay
            ></DotLottiePlayer>
          </div>
        </div>
        <div className={styles.packages_section} id="packages">
          <div className={styles.packages_heading}>
            Social Media Growth - Instagram{" "}
            <span>[Get your first month at 20% off]</span>
          </div>
        </div>
        <div className={styles.package_container_section}>
          <div className={styles.package_container}>
            <img
              src="/power-player/pp-package-basic.svg"
              alt=""
              className={styles.package_basic_img}
            />
            <h3>Basic [$997/Month]</h3>
            <h4>Weekly Posting Schedule</h4>
            <div className={styles.bullet_points_container}>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                2 talking head video (reels)
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                2 solo design posts
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                2 carousel posts
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Guaranteed growth
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Scripts included for videos
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Free strategy session before beginning
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Free community engagement on Instagram with up to 50 accounts
                (daily)
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Free repurposing for your social channels: LinkedIn, TikTok,
                YouTube and Facebook.
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Free weekly reports on channel growth statistics
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                100% money-back guarantee
              </div>
            </div>
            <div className={styles.package_end}>
              <div className={styles.package_preparation}>
                Preparation Time: 1 Week
              </div>
              <div className={styles.package_button}>
                <a href="#power_contact">Apply Now</a>
              </div>
            </div>
          </div>
          <div className={styles.package_container}>
            <img
              src="/power-player/pp-package-standard.svg"
              alt=""
              className={styles.package_basic_img}
            />
            <h3>Standard [$1497/Month]</h3>
            <h4>Weekly Posting Schedule</h4>
            <div className={styles.bullet_points_container}>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                3 talking head video (reels)
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                1 animated video (reels)
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                3 solo design posts
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                3 carousel posts
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Up to 4 highlight videos [most important sections cut up from
                your long-form content]
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Guaranteed growth
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Scripts included for videos
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Free strategy session before beginning
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Free community engagement on Instagram with up to 80 accounts
                (daily)
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Free repurposing for your social channels: LinkedIn, TikTok,
                YouTube and Facebook.
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Free weekly reports on channel growth statistics
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                100% money-back guarantee
              </div>
            </div>
            <div className={styles.package_end}>
              <div className={styles.package_preparation}>
                Preparation Time: 2 Weeks
              </div>
              <div className={styles.package_button}>
                <a href="#power_contact">Apply Now</a>
              </div>
            </div>
          </div>
          <div className={styles.package_container}>
            <img
              src="/power-player/pp-package-premium.svg"
              alt=""
              className={styles.package_basic_img}
            />
            <h3>Premium [$2497/Month]</h3>
            <h4>Weekly Posting Schedule</h4>
            <div className={styles.bullet_points_container}>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                4 talking head video (reels)
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                2 animated videos (reels)
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                4 solo design posts
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                4 carousel posts
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Up to 8 highlight videos [most important sections cut up from
                your long-form content]
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Guaranteed growth
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Scripts included for videos
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Free strategy session before beginning
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Free community engagement on Instagram with up to 120 accounts
                (daily) and reply to your DMs
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Free repurposing for your social channels: LinkedIn, TikTok,
                YouTube and Facebook.
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                Free weekly reports on channel growth statistics
              </div>
              <div className={styles.package_bullet_point}>
                <img
                  src="/power-player/plus.svg"
                  alt=""
                  className={styles.bullet_point_plus}
                />
                100% money-back guarantee
              </div>
            </div>
            <div className={styles.package_end}>
              <div className={styles.package_preparation}>
                Preparation Time: 1 Month
              </div>
              <div className={styles.package_button}>
                <a href="#power_contact">Apply Now</a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer_section}>
          <div className={styles.footer_buttons}>
            <div
              className={`${styles.footer_button} ${styles.faq_button}`}
              onClick={handleFaqOpen}
            >
              FAQs
            </div>
            <div className={styles.footer_button}>
              <a href="#power_container">Scroll Up</a>
            </div>
            <div className={styles.footer_button}>
              <a href="/" target="_blank" rel="noopener noreferrer">
                Go Home
              </a>
            </div>
          </div>
          <ContactForm />
          <div className={styles.footer_bottom}>
            <img
              className={styles.footer_bottom_logo}
              src="/power-player/aristocat-logo-pp.svg"
              alt=""
            />
            <div className={styles.footer_bottom_divider}></div>
            <div className={styles.footer_bottom_text}>
              Copyright Aristocat Studio Pvt Ltd - All Rights Reserved - 2024
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function LoadingScreen({ onLoading, onCoverIsReady, onPlayCoverAnimation }) {
  const [buttonClicked, setButtonClicked] = useState(false);

  useEffect(() => {
    document.body.classList.add("no-scroll");
  }, []);

  return (
    <div
      className={`${styles.loading_container} ${
        buttonClicked && styles.invisble
      }`}
    >
      <div className={styles.loading_copy}>
        Are you ready to get your first 1000 true fans?
      </div>
      <div className={styles.loading_button_container}>
        {!onLoading && onCoverIsReady && (
          <div
            className={`${styles.loading_button}`}
            onClick={() => {
              setButtonClicked(true);
              document.body.classList.remove("no-scroll");
              onPlayCoverAnimation();
            }}
          >
            Discover The Magic Of The Power Player
          </div>
        )}
      </div>
    </div>
  );
}

const VideoPlayer = ({ src, type, thumbnail }) => {
  return (
    <div className={styles.boost_video_container}>
      <video controls width="50%" poster={thumbnail}>
        <source src={src} type={type} />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

function ContactForm() {
  return (
    <form
      name="power_contact"
      method="POST"
      id="power_contact"
      className={styles.form_power_contact}
      data-netlify="true"
    >
      <input type="hidden" name="form-name" value="power_contact" />
      <h2 className={styles.form_heading}>Interested in working with us?</h2>
      <label htmlFor="name" className={styles.form_label}>
        Full Name*
      </label>
      <input
        type="text"
        className={styles.form_input}
        name="name"
        id="name"
        required
      />
      <label htmlFor="email" className={styles.form_label}>
        Email*
      </label>
      <input
        type="email"
        className={styles.form_input}
        id="email"
        name="email"
        required
      />
      <label htmlFor="website" className={styles.form_label}>
        Website
      </label>
      <input
        type="text"
        className={styles.form_input}
        name="website"
        id="website"
      />
      <label htmlFor="message" className={styles.form_label}>
        Your Query*
      </label>
      <textarea
        rows="4"
        className={styles.form_input}
        id="message"
        name="message"
        required
      ></textarea>

      <button
        className={styles.form_button}
        type="submit"
        id="submit_button_dt"
      >
        Submit
      </button>
    </form>
  );
}

function FAQModule({ onFaqOpen }) {
  return (
    <div className={styles.overlay_faq}>
      <span
        className={styles.close}
        onClick={() => {
          onFaqOpen();
        }}
      >
        &times;
      </span>
      <h1>FAQ</h1>
      <h2>1. Which social media platform does this program work best for?</h2>
      <p>
        The Power Player program targets Instagram as its primary platform.
        Although the content can be repurposed for any platform, we chose
        Instagram as it has proven to be the most effective in promoting visual
        content. Unlike other platforms, we also have the ability to ‘lead the
        algorithm’ in a certain direction and not rely on chances of going
        viral.
      </p>

      <h2>
        2. Can I join the program even if I have a new account or have no
        followers on Instagram?
      </h2>
      <p>
        Absolutely! This program is for everyone. Fair warning: it might take
        some time for you to see growth. Consistency, in this case, is an
        absolute must. This is a DWY(Done-with-you) program, after all.
      </p>
      <h2>3. Do you offer money-back guarantees for your plans?</h2>
      <p>
        Absolutely! If we can’t deliver according to our promises of making your
        life easier, growing your presence, and building up your credibility,
        you can have 100% of your money back.
      </p>

      <h2>4. Is there a minimum lock-in period for this program?</h2>
      <p>
        Nope! It’s entirely pay-as-you-go month-to-month. You can cancel anytime
        you want for whatever reason.
      </p>

      <h2>
        5. I’m not comfortable recording myself on camera. Is there another way
        you can grow my influence?
      </h2>
      <p>
        Since our content strategy is focused on video content, we highly
        recommend that you show up in person to solidify your personal brand.
        But if you absolutely do not want to record yourself, we can devise
        another strategy. Get in touch with us.
      </p>

      <h2>6. Can my content go VIRAL?</h2>
      <p>
        It can, but it’s not something we can guarantee. What we do guarantee
        though is freeing up your time, delivering consistent, quality content
        that grabs eyeballs and makes you look like an authority in your
        industry.
      </p>

      <h2>7. Do you use any sort of bot or automation software to do this?</h2>
      <p>
        Nope! Everything is done manually by our team of artists, editors,
        animators, and social media managers. There’s no risk of your account
        getting penalized or deleted.
      </p>

      <h2>8. Are there discounts for paying in advance?</h2>
      <p>
        Yep! If you pay for the whole year, you get 2 months absolutely free.
        So, you’re essentially paying for 10 months. Plus, a 20% discount is
        available for everyone for their first month.
      </p>
      <h2>9. I have more questions; where can I contact you?</h2>
      <p>
        You can reach out to me personally at
        <span> hello@aristocatstudio.com</span>
      </p>
    </div>
  );
}
