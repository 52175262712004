import "./App.css";
import { useState, useEffect } from "react";
import LoadingScreen from "./LoadingScreen";
import ReachOut from "./ReachOut";
import Roof from "./Roof";
import Floor from "./Floor";
import Portfolio from "./Portfolio";
import Testimonial from "./Testimonial";
import Footer from "./Footer";
import PopupModule from "./PopupModule";
import ReachOutMobile from "./ReachOutMobile";
import RoofMobile from "./RoofMobile";
import FloorMobile from "./FloorMobile";
import TestimonialMobile from "./TestimonialMobile";
import FooterMobile from "./FooterMobile";
import MeetTheTeam from "./MeetTheTeam";
import PowerPlayer from "./PowerPlayer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import usePreventZoom from "./usePreventZoom";

export default function App() {
  usePreventZoom();
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<MeetTheTeam />} />
        <Route path="/power" element={<PowerPlayer />} />
      </Routes>
    </BrowserRouter>
  );
}

function Home() {
  const [activePortfolio, setActivePortfolio] = useState(null);
  const [activePopup, setActivePopup] = useState(null);
  const [roofIsReady, setRoofIsReady] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setLoading(false);
    };
    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  function handleActivePortfolio(name) {
    setActivePortfolio((prevName) => {
      if (name !== prevName) {
        return name;
      }
    });
  }

  function handleCloseActivePortfolio() {
    setActivePortfolio(null);
  }

  function handleActivePopup(name) {
    setActivePopup((prevName) => {
      if (name !== prevName) {
        return name;
      }
    });
  }

  function handleCloseActivePopup() {
    setActivePopup(null);
  }

  function handleSetRoofIsReady() {
    setRoofIsReady(true);
  }

  return (
    <>
      <LoadingScreen onLoading={loading} onRoofIsReady={roofIsReady} />
      {activePortfolio && (
        <Portfolio
          key="portfolio"
          name={activePortfolio}
          onCloseActivePortfolio={handleCloseActivePortfolio}
          onActivePortfolio={handleActivePortfolio}
        />
      )}

      {activePopup && (
        <PopupModule
          onCloseActivePopup={handleCloseActivePopup}
          type={activePopup}
        />
      )}

      <div className="desktop_app" id="desktop_app">
        <img
          src="/pics/layer-0-sky-alt-1.png"
          alt="layer-0-sky"
          className="sky_img"
        />
        <img
          src="/pics/building-main-scroll.png"
          alt="building-main"
          className="building_main_img"
        />

        <ReachOut />
        <Roof onSetRoofIsReady={handleSetRoofIsReady} />
        <Floor
          name="illustration"
          onActivePortfolio={handleActivePortfolio}
          currentPortfolio={activePortfolio}
        />
        <Floor
          name="animation"
          onActivePortfolio={handleActivePortfolio}
          currentPortfolio={activePortfolio}
        />
        <Floor
          name="content"
          onActivePortfolio={handleActivePortfolio}
          currentPortfolio={activePortfolio}
        />
        <Floor
          name="webdev"
          onActivePortfolio={handleActivePortfolio}
          currentPortfolio={activePortfolio}
        />
        <Testimonial />
        <Footer
          onActivePopup={handleActivePopup}
          onCloseActivePopup={handleCloseActivePopup}
        />
      </div>

      <div className="mobile_app" id="mobile_app">
        <div className="layer_sky_mb"></div>
        <ReachOutMobile />
        <RoofMobile onSetRoofIsReady={handleSetRoofIsReady} />
        <FloorMobile
          name="illustration"
          onActivePortfolio={handleActivePortfolio}
          currentPortfolio={activePortfolio}
        />
        <FloorMobile
          name="animation"
          onActivePortfolio={handleActivePortfolio}
          currentPortfolio={activePortfolio}
        />
        <FloorMobile
          name="content"
          onActivePortfolio={handleActivePortfolio}
          currentPortfolio={activePortfolio}
        />
        <FloorMobile
          name="webdev"
          onActivePortfolio={handleActivePortfolio}
          currentPortfolio={activePortfolio}
        />
        <TestimonialMobile />
        <FooterMobile onActivePopup={handleActivePopup} />
      </div>
    </>
  );
}
