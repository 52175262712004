import { DotLottiePlayer, PlayerEvents } from "@dotlottie/react-player";
import styles from "./ReachOut.module.css";
import { useCallback, useEffect, useRef } from "react";

export default function ReachOut() {
  const reachOutContainerRef = useRef(null);
  const reachOutRef = useRef(null);
  const isInView = useRef(false);
  const buttonIsReady = useRef(false);

  const checkInView = useCallback(() => {
    if (buttonIsReady.current) {
      const rect = reachOutContainerRef.current.getBoundingClientRect();
      isInView.current = rect.top < window.innerHeight && rect.bottom >= 0;
      if (isInView.current) {
        reachOutRef.current?.play();
      } else {
        reachOutRef.current?.stop();
      }
    }
  }, [buttonIsReady, isInView]);

  useEffect(() => {
    document.addEventListener("scroll", checkInView);
    return () => {
      document.removeEventListener("scroll", checkInView);
    };
  }, [checkInView]);

  return (
    <div ref={reachOutContainerRef} className={styles.reach_out_container}>
      <DotLottiePlayer
        ref={reachOutRef}
        src="./lotties/reach-out-button.lottie"
        background="transparent"
        speed="1"
        direction="1"
        mode="normal"
        className={styles.reach_out_lottie}
        loop
        autoplay
        onEvent={(event) => {
          // Listen for player ready
          if (event === PlayerEvents.Ready) {
            buttonIsReady.current = true;
          }
        }}
      ></DotLottiePlayer>

      <div className={styles.reach_out_button}>
        <a className={styles.reach_out_button_link} href="#contact"></a>
        <img
          src="./pics/reach-out.png"
          alt=""
          className={styles.reach_out_normal}
        />
        <img
          src="./pics/reach-out-glow.png"
          alt=""
          className={styles.reach_out_glow}
        />
      </div>
    </div>
  );
}
