import styles from "./Footer.module.css";
import { useKey } from "./useKey";
// import { handleDesktopSubmit } from "./utils";

export default function Footer({ onActivePopup, onCloseActivePopup }) {
  useKey("Escape", onCloseActivePopup);

  return (
    <div className={styles.footer}>
      <ContactForm />
      <FooterButtons onActivePopup={onActivePopup} />
      <SocialButtons />
      <div className={styles.footer_fan}>
        <img
          className={styles.rotate_fan}
          src={`./pics/illustration-fan.svg`}
          alt="AC Fan"
        />
      </div>
    </div>
  );
}

function ContactForm() {
  return (
    <form
      name="contact"
      method="POST"
      id="contact"
      className={styles.contact_form}
      data-netlify="true"
    >
      <input type="hidden" name="form-name" value="contact" />
      <h1 className={styles.form_heading}>Interested in working with us?</h1>
      <input
        type="text"
        placeholder="Name"
        className={styles.neon_input}
        name="name"
        id="name"
        required
      />
      <input
        type="email"
        placeholder="Email"
        className={styles.neon_input}
        id="email"
        name="email"
        required
      />
      <textarea
        placeholder="Your Query"
        rows="4"
        className={styles.neon_input}
        id="message"
        name="message"
        required
      ></textarea>

      <button
        className={styles.neon_button}
        type="submit"
        id="submit_button_dt"
      >
        Submit
      </button>
    </form>
  );
}

function FooterButtons({ onActivePopup }) {
  return (
    <>
      <div className={styles.footer_scroll_container}>
        <img
          href="#desktop_app"
          src="./pics/footer-scroll.png"
          alt=""
          className={styles.scroll_text_dt}
        />
        <a className={styles.scroll_link} href="#desktop_app">
          Scroll Up
        </a>
      </div>
      <div className={styles.footer_scroll_arrow_container}>
        <img
          src="./pics/footer-scroll-arrow.svg"
          alt=""
          className={styles.scroll_arrow}
        />
      </div>
      <div
        className={`${styles.footer_options} ${styles.footer_options_faq}`}
        onClick={() => {
          onActivePopup("faq");
        }}
      >
        FAQs
      </div>

      <div
        className={`${styles.footer_options} ${styles.footer_options_pp}`}
        onClick={() => {
          onActivePopup("privacy");
        }}
      >
        Privacy Policy
      </div>

      <div
        className={`${styles.footer_options} ${styles.footer_options_tos}`}
        onClick={() => {
          onActivePopup("terms");
        }}
      >
        Terms of Service
      </div>

      <div
        className={`${styles.footer_options} ${styles.footer_options_about}`}
      >
        <a href="/about">Meet The Team</a>
      </div>
    </>
  );
}

function SocialButtons() {
  return (
    <>
      <div className={styles.social_buttons_row_top}>
        <div
          className={`${styles.social_button} ${styles.upwork}`}
          onClick={() => {
            window.open("https://www.upwork.com/freelancers/arkab", "_blank");
          }}
        >
          <img
            src="./pics/upwork.png"
            alt=""
            className={styles.social_normal}
          />
          <img
            src="./pics/upwork-glow.png"
            alt=""
            className={styles.social_glow}
          />
        </div>
        <div
          className={`${styles.social_button} ${styles.lottie}`}
          onClick={() => {
            window.open("https://lottiefiles.com/091qv1joz1scc5bf", "_blank");
          }}
        >
          <img
            src="./pics/lottie.png"
            alt=""
            className={styles.social_normal}
          />
          <img
            src="./pics/lottie-glow.png"
            alt=""
            className={styles.social_glow}
          />
        </div>
        <div
          className={`${styles.social_button} ${styles.youtube}`}
          onClick={() => {
            window.open("https://www.youtube.com/@ADayInTime99", "_blank");
          }}
        >
          <img
            src="./pics/youtube.png"
            alt=""
            className={styles.social_normal}
          />
          <img
            src="./pics/youtube-glow.png"
            alt=""
            className={styles.social_glow}
          />
        </div>
      </div>

      <div className={styles.social_buttons_row_bottom}>
        <div
          className={`${styles.social_button} ${styles.behance}`}
          onClick={() => {
            window.open("https://www.behance.net/adayintime2020", "_blank");
          }}
        >
          <img
            src="./pics/behance.png"
            alt=""
            className={styles.social_normal}
          />
          <img
            src="./pics/behance-glow.png"
            alt=""
            className={styles.social_glow}
          />
        </div>
        <div
          className={`${styles.social_button} ${styles.insta}`}
          onClick={() => {
            window.open(
              "https://www.instagram.com/aristocat.studio/",
              "_blank"
            );
          }}
        >
          <img src="./pics/insta.png" alt="" className={styles.social_normal} />
          <img
            src="./pics/insta-glow.png"
            alt=""
            className={styles.social_glow}
          />
        </div>
        <div
          className={`${styles.social_button} ${styles.linkedin}`}
          onClick={() => {
            window.open(
              "https://www.linkedin.com/in/aristocat-studio-now",
              "_blank"
            );
          }}
        >
          <img
            src="./pics/linkedin.png"
            alt=""
            className={styles.social_normal}
          />
          <img
            src="./pics/linkedin-glow.png"
            alt=""
            className={styles.social_glow}
          />
        </div>
      </div>
    </>
  );
}
