import styles from "./Portfolio.module.css";
import { useKey } from "./useKey";
import { useEffect, useState } from "react";

const illustration = [
  {
    img_link: "./portfolio/logo-design.png",
    title: "Logo Design",
    description:
      "Forge a powerful identity with our tailored logo designs. From sleek and modern to timeless and elegant, we craft logos that resonate with your brand's essence, leaving a lasting imprint on your audience.",
    portfolio: "https://www.behance.net/gallery/167300723/Logo-Design",
  },
  {
    img_link: "./portfolio/web-illustration.png",
    title: "Website Illustrations",
    description:
      "Elevate your website with engaging visuals that tell your story and leave a lasting impression. Our talented artists craft visually captivating scenes that resonate with your audience. From whimsical characters to sleek designs, we tailor every detail to reflect your unique identity.",
    portfolio:
      "https://www.behance.net/gallery/192647431/Website-Illustration-and-Animation-for-Z-Staffing",
  },
  {
    img_link: "./portfolio/branding.png",
    title: "Branding",
    description:
      "Elevate your brand presence with our comprehensive branding solutions. From logo design to cohesive visual identity, we help you establish a strong and memorable brand image that resonates with your audience, and drives greater clicks and conversions.",
    portfolio: "https://www.behance.net/gallery/162613617/Pure-Sip-Juice-Brand",
  },
  {
    img_link: "./portfolio/character-design.png",
    title: "Character Design",
    description:
      "Dive into a world of imagination with our captivating character designs. Whether whimsical or realistic, our creations breathe life into stories and captivate audiences across diverse mediums. No matter the niche, we can create characters that fit the bill perfectly.",
    portfolio: "https://www.behance.net/gallery/174768479/CHARACTER-DESIGN",
  },
  {
    img_link: "./portfolio/eclectic.png",
    title: "Eclectic Illustrations",
    description:
      "Explore a diverse array of illustrations that inspire creativity and imagination. From vibrant and playful to sophisticated and serene, our collection offers something for every project and presentation.",
    portfolio: "https://www.behance.net/gallery/156840805/Our-Illustrations",
  },
];

const animation = [
  {
    img_link: "./portfolio/logo-animation.png",
    title: "Logo Animation",
    description:
      "Animated versions of your logo, bringing it to life with motion and effects to captivate audiences and enhance brand recognition.",
    portfolio: "https://youtu.be/9zLo5LcE6YQ",
  },
  {
    img_link: "./portfolio/lottie-animation.png",
    title: "Lottie Animations",
    description:
      "High-quality, scalable vector animations compatible with web and mobile apps, adding interactivity and visual appeal to user interfaces.",
    portfolio: "https://lottiefiles.com/091qv1joz1scc5bf",
  },
  {
    img_link: "./portfolio/reels-animation.png",
    title: "Instagram Reels",
    description:
      "Short, engaging video clips tailored for Instagram, perfect for showcasing products, services, or brand personality in a fun and dynamic way.",
    portfolio: "https://www.instagram.com/sudarshan.kdot/reels/",
  },
  {
    img_link: "./portfolio/explainer-videos.png",
    title: "Explainer Videos",
    description:
      "Concise, animated presentations that simplify complex concepts or processes, ideal for educating audiences and promoting products or services.",
    portfolio: "https://youtu.be/BRBAFqQUsI0",
  },
  {
    img_link: "./portfolio/youtube-animation.png",
    title: "YouTube Animations",
    description:
      "Dynamic animated content optimized for YouTube, designed to entertain, inform, or engage viewers and increase audience retention.",
    portfolio: "https://youtu.be/HsgT6OgcwsM",
  },
  {
    img_link: "./portfolio/character-animation.png",
    title: "Character Animations",
    description:
      "Animated characters infused with personality and charm, suitable for storytelling, branding, or adding flair to marketing campaigns.",
    portfolio: "https://youtu.be/G9qwcr1ovJM",
  },
];

const content = [
  {
    img_link: "./portfolio/copywriting.png",
    title: "Copywriting",
    description:
      "At Aristocat Studio, our expert copywriters craft compelling copy that converts. We study your brand, capture its ethos, and create enticing copy that drives action. With us, your promotions will capture attention and drive users to your business.",
    portfolio:
      "https://drive.google.com/drive/folders/14q7isvwFI67mUN1hIQDZALwn3JRhxHcn",
  },
  {
    img_link: "./portfolio/scriptwriting.png",
    title: "Scriptwriting",
    description:
      "Create captivating narratives with Aristocat Studio! Our script writing is purr-fect, blending wit and charm for a lasting impression. From quirky commercials to corporate cool, we've got the claws to make your message roar!",
    portfolio:
      "https://drive.google.com/drive/folders/14q7isvwFI67mUN1hIQDZALwn3JRhxHcn",
  },
];

const web = [
  {
    img_link: "./portfolio/adayintime.png",
    title: "A Day In Time",
    description:
      "Explore imagination with A Day In Time Studio! Winner of the LottieFiles X Design Buddies challenge, our WordPress website showcases vibrant design and animation. From whimsical adventures to heartfelt narratives, delve into mesmerizing storytelling through colorful visuals.",
    portfolio: "https://adayintime.life",
  },
  {
    img_link: "./portfolio/aristocatstudio.png",
    title: "Aristocat Studio",
    description:
      "Where are you now? Well, look around! You’re in the neighborhood of Aristocat Studio, and our website matches the techno-futuristic world we are helping create! Built using React, this website uses lightweight Lottie animations to bring our departments to life on the web.",
    portfolio: "https://aristocatstudio.com",
  },
];

const portfolioOrder = ["illustration", "animation", "content", "webdev"];

export default function Portfolio({
  name,
  onCloseActivePortfolio,
  onActivePortfolio,
}) {
  const [jsonData, setJsonData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [bgLoaded, setBgLoaded] = useState(false);
  const [fgLoaded, setFgLoaded] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  function changeDepartment(direction = 1) {
    setFgLoaded(false);
    setImgLoaded(false);
    let ind = portfolioOrder.indexOf(name);
    let newIndex = ind + direction;
    if (newIndex === -1) {
      newIndex = portfolioOrder.length - 1;
    }
    const newPortfolio = portfolioOrder[newIndex];

    switch (newPortfolio) {
      case "illustration":
        setJsonData(illustration);
        onActivePortfolio("illustration");
        break;
      case "animation":
        setJsonData(animation);
        onActivePortfolio("animation");
        break;
      case "content":
        setJsonData(content);
        onActivePortfolio("content");
        break;
      case "webdev":
        setJsonData(web);
        onActivePortfolio("webdev");
        break;
      default:
        setJsonData(illustration);
        onActivePortfolio("illustration");
    }
    setCurrentIndex(0);
  }

  function changeSlide(direction = 1) {
    setImgLoaded(false);
    setCurrentIndex((prevIndex) => {
      let newIndex = prevIndex + direction;
      if (newIndex === -1) {
        newIndex = jsonData.length - 1;
      }
      return newIndex % jsonData.length;
    });
  }

  // useEffect(() => {
  //   // Disable scrolling when the component mounts
  //   document.body.classList.add("no-scroll");

  //   // Re-enable scrolling when the component unmounts
  //   return () => {
  //     document.body.classList.remove("no-scroll");
  //   };
  // }, []);

  useEffect(() => {
    if (name === "illustration") {
      setJsonData(illustration);
    } else if (name === "animation") {
      setJsonData(animation);
    } else if (name === "content") {
      setJsonData(content);
    } else {
      setJsonData(web);
    }

    if (jsonData.length > 0 && currentIndex === -1) {
      setCurrentIndex(0);
    }

    // Function to cycle through the array every 5 seconds
    const interval = setInterval(() => {
      setImgLoaded(false);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % jsonData.length);
    }, 5000);

    // Clean up the interval to prevent memory leaks
    return () => clearInterval(interval);
  }, [jsonData, name, currentIndex]);

  useKey("Escape", onCloseActivePortfolio);

  return (
    <div className={styles.portfolio_container_holder}>
      <div
        className={styles.overlay}
        onClick={() => {
          onCloseActivePortfolio();
        }}
      ></div>
      <span
        className={`${styles.close} ${styles[name]}`}
        onClick={() => {
          onCloseActivePortfolio();
        }}
      >
        &times;
      </span>
      <div
        className={`${styles.portfolio_container} ${bgLoaded && styles.loaded}`}
      >
        <img
          src="./portfolio/portfolio-panel-off.png"
          alt="portfolio bg"
          className={`${styles.portfolio_bg_img}`}
          onLoad={() => {
            setBgLoaded(true);
          }}
        />
        <img
          src={`./portfolio/portfolio-${name}-screen.png`}
          alt="portfolio fg"
          className={`${styles.portfolio_fg_img} ${fgLoaded && styles.loaded}`}
          onLoad={() => {
            setFgLoaded(true);
          }}
        />

        <img
          src="./portfolio/portfolio-panel-off-mb.png"
          alt="portfolio bg"
          className={`${styles.portfolio_bg_img_mb} ${
            bgLoaded && styles.loaded
          }`}
          onLoad={() => {
            setBgLoaded(true);
          }}
        />
        <img
          src={`./portfolio/portfolio-${name}-screen-mb.png`}
          alt="portfolio fg"
          className={`${styles.portfolio_fg_img_mb} ${
            fgLoaded && styles.loaded
          }`}
          onLoad={() => {
            setFgLoaded(true);
          }}
        />

        {bgLoaded && fgLoaded && (
          <>
            <div
              className={`${styles.chevron_left} ${styles[name]}`}
              onClick={() => {
                changeDepartment(-1);
              }}
            >
              <svg
                version="1.1"
                id="Layer_2_00000014608274672672041600000018295009715622646176_"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 84.5 62.8"
              >
                <g id="Illustration_Panel">
                  <g>
                    <polygon points="69.4,62.8 38.1,31.5 69.6,0 84.5,0 53,31.5 84.3,62.8 		" />
                    <polygon points="31.3,62.8 0,31.5 31.5,0 46.4,0 14.9,31.5 46.2,62.8 		" />
                  </g>
                </g>
              </svg>
            </div>

            <div
              className={`${styles.chevron_right} ${styles[name]}`}
              onClick={() => {
                changeDepartment(1);
              }}
            >
              <svg
                id="Layer_2"
                data-name="Layer 2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 84.5 62.81"
              >
                <defs></defs>
                <g id="Content_Panel" data-name="Content Panel">
                  <g>
                    <polygon
                      className="cls-1"
                      points=".19 62.81 31.5 31.5 0 0 14.93 0 46.43 31.5 15.12 62.81 .19 62.81"
                    />
                    <polygon
                      className="cls-1"
                      points="38.26 62.81 69.57 31.5 38.07 0 53 0 84.5 31.5 53.19 62.81 38.26 62.81"
                    />
                  </g>
                </g>
              </svg>
            </div>

            <span
              className={`${styles.next_text} ${styles[name]}`}
              onClick={() => changeSlide(1)}
            >
              NEXT
            </span>

            <span
              className={`${styles.prev_text} ${styles[name]}`}
              onClick={() => changeSlide(-1)}
            >
              PREVIOUS
            </span>
            {!imgLoaded && <Loader name={name} />}

            <img
              className={`${styles.slider_image} ${imgLoaded && styles.loaded}`}
              src={jsonData[currentIndex]?.img_link}
              onClick={() => {
                window.open(jsonData[currentIndex].portfolio, "_blank");
              }}
              alt=""
              onLoad={() => {
                setImgLoaded(true);
              }}
            />

            <div className={`${styles.slider_text_container} ${styles[name]}`}>
              <span className={`${styles.slider_text_title} ${styles[name]}`}>
                {jsonData[currentIndex]?.title}
              </span>
              <span className={`${styles.slider_text_body} ${styles[name]}`}>
                {jsonData[currentIndex]?.description}
              </span>
            </div>

            <div
              className={`${styles.show_more_button} ${styles[name]}`}
              onClick={() => {
                window.open(jsonData[currentIndex].portfolio, "_blank");
              }}
            >
              <svg
                className={`${styles.show_more_svg}`}
                id="Layer_3"
                data-name="Layer 3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 215.12 55.85"
              >
                <defs></defs>
                <g id="Layer_1-3" data-name="Layer 3">
                  <path
                    className="cls-3"
                    d="M27.69,5.53L5.94,25.67c-1.56,1.44-2.44,3.46-2.44,5.59v13.49c0,4.2,3.41,7.61,7.61,7.61h171.14c1.92,0,3.76-.72,5.17-2.03l21.75-20.14c1.56-1.44,2.44-3.46,2.44-5.59v-13.49c0-4.2-3.41-7.61-7.61-7.61H32.86c-1.92,0-3.76.72-5.17,2.03Z"
                  />
                </g>
              </svg>
              <span className={`${styles.show_more_text}`}>Show More</span>
            </div>

            <div className={styles.slider_dots}>
              {jsonData?.map((item, index) => {
                switch (name) {
                  case "illustration":
                    return (
                      <div
                        className={`${styles.dot} ${
                          index === currentIndex && styles.active
                        } ${styles.dot_illustration}`}
                        onClick={() => {
                          setCurrentIndex(index);
                        }}
                      ></div>
                    );
                  case "animation":
                    return (
                      <div
                        className={`${styles.dot} ${
                          index === currentIndex && styles.active
                        } ${styles.dot_animation}`}
                        onClick={() => {
                          setCurrentIndex(index);
                        }}
                      ></div>
                    );
                  case "content":
                    return (
                      <div
                        className={`${styles.dot} ${
                          index === currentIndex && styles.active
                        } ${styles.dot_content}`}
                        onClick={() => {
                          setCurrentIndex(index);
                        }}
                      ></div>
                    );
                  case "webdev":
                    return (
                      <div
                        className={`${styles.dot} ${
                          index === currentIndex && styles.active
                        } ${styles.dot_webdev}`}
                        onClick={() => {
                          setCurrentIndex(index);
                        }}
                      ></div>
                    );
                  default:
                    return (
                      <div
                        className={`${styles.dot} ${
                          index === currentIndex && styles.active
                        } ${styles.dot_illustration}`}
                        onClick={() => {
                          setCurrentIndex(index);
                        }}
                      ></div>
                    );
                }
              })}
            </div>

            <div
              className={`${styles.tab_illustration} ${
                name === "illustration" && styles.active
              }`}
              onClick={() => {
                if (name !== "illustration") {
                  setFgLoaded(false);
                  setImgLoaded(false);
                  setJsonData(illustration);
                  setCurrentIndex(0);
                  onActivePortfolio("illustration");
                }
              }}
            >
              Illustration
            </div>
            <div
              className={`${styles.tab_animation} ${
                name === "animation" && styles.active
              }`}
              onClick={() => {
                if (name !== "animation") {
                  setFgLoaded(false);
                  setImgLoaded(false);
                  setJsonData(animation);
                  setCurrentIndex(0);
                  onActivePortfolio("animation");
                }
              }}
            >
              Animation
            </div>
            <div
              className={`${styles.tab_content} ${
                name === "content" && styles.active
              }`}
              onClick={() => {
                if (name !== "content") {
                  setFgLoaded(false);
                  setImgLoaded(false);
                  setJsonData(content);
                  setCurrentIndex(0);
                  onActivePortfolio("content");
                }
              }}
            >
              Content
            </div>
            <div
              className={`${styles.tab_webdev} ${
                name === "webdev" && styles.active
              }`}
              onClick={() => {
                if (name !== "webdev") {
                  setFgLoaded(false);
                  setImgLoaded(false);
                  setJsonData(web);
                  setCurrentIndex(0);
                  onActivePortfolio("webdev");
                }
              }}
            >
              Web Dev
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function Loader({ name }) {
  return <div className={`${styles.loader} ${styles[name]}`}></div>;
}
