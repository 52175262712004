import { useState, useEffect } from "react";
import styles from "./Testimonial.module.css";

const testimonialsList = [
  {
    statement:
      '"What can I say? My dream to create high-quality animated content for my YouTube channel was only possible thanks to these guys! I\'m forever grateful to the highly talented team at Aristocat Studio."',
    name: "Anton Giuroiu",
    des: "Founder, MKRS Media",
  },
  {
    statement:
      "Was a pleasure working with Arka! He and his team did an amazing job helping me with the animated I needed. Would be happy to work together again! Thank you!",
    name: "Olga Vakhromeeva",
    des: "Upwork Client",
  },
  {
    statement:
      "Arka and his team was a real pleasure to work with. Their quality of work was great and everything was delivered in a timely manner. Would definitely work with them again for future projects",
    name: "Gerry Tan",
    des: "Upwork Client",
  },
  {
    statement:
      "A Day In Time Studio - Winner of the LottieFiles X Design Buddies Landing Page Challenge",
    name: "LottieFiles.com",
    des: "Designbuddies.com",
  },
  {
    statement:
      "Was so grateful for the teams efforts to get our project completed on time. And the end product looked great!",
    name: "Jalal Karim",
    des: "Upwork Client",
  },
  {
    statement:
      "Took the time to understand what was required, provided direction on how they would proceed and then nailed it first go",
    name: "Kirk Land",
    des: "Upwork Client",
  },
  {
    statement:
      "Arka was very professional and delivered his work in a timely manner. The communication with him was very fluent and we'll be happy to work with him on future projects.",
    name: "Sol Crater",
    des: "Crater Studios",
  },
];

export default function Testimonial() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);

  function changeTestimonial() {
    setOpacity(0);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => {
        let newIndex = prevIndex + 1;
        return newIndex % testimonialsList.length;
      });
    }, 500);
  }

  useEffect(() => {
    // Function to cycle through the array every 5 seconds
    const interval = setInterval(() => {
      changeTestimonial();
    }, 7000);

    // Clean up the interval to prevent memory leaks
    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    setOpacity(1); // Set opacity back to 1 (fully visible) when testimonial changes
  }, [currentIndex]);

  return (
    <div className={styles.testimonial_text} style={{ opacity: opacity }}>
      <p className={styles.testimonial_text_p}>
        {testimonialsList[currentIndex].statement}
      </p>

      <p
        className={styles.testimonial_text_p_name}
      >{`-${testimonialsList[currentIndex].name}-`}</p>
      <p className={styles.testimonial_text_p_des}>
        {testimonialsList[currentIndex].des}
      </p>
    </div>
  );
}
