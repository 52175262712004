import { useCallback, useRef, useEffect } from "react";
import styles from "./RoofMobile.module.css";
import { DotLottiePlayer, PlayerEvents } from "@dotlottie/react-player";

export default function RoofMobile({onSetRoofIsReady}) {
  const containerRef = useRef(null);
  const roofLottieRef = useRef(null);
  const ppButtonLottieRef = useRef(null);

  const isInView = useRef(false);
  const roofIsReady = useRef(false);

  const checkInView = useCallback(() => {
    if (roofIsReady.current) {
      const rect = containerRef.current.getBoundingClientRect();
      isInView.current = rect.top < window.innerHeight && rect.bottom >= 0;
      if (isInView.current) {
        roofLottieRef.current?.play();
      } else {
        roofLottieRef.current?.stop();
      }
    }
  }, [roofIsReady, isInView]);

  useEffect(() => {
    document.addEventListener("scroll", checkInView);
    return () => {
      document.removeEventListener("scroll", checkInView);
    };
  }, [checkInView]);

  return (
    <div ref={containerRef} className={styles.roof_container_mb}>
      <div className={styles.roof_mb}>
        <DotLottiePlayer
          ref={roofLottieRef}
          src="/lotties/rooftop-mb.lottie"
          className={styles.roof_lottie_mb}
          loop
          autoplay
          onEvent={(event) => {
            // Listen for player ready
            if (event === PlayerEvents.Ready) {
              roofIsReady.current = true;
              onSetRoofIsReady();
            }
          }}
        ></DotLottiePlayer>
        <a href="/power">
          <DotLottiePlayer
            onClick={() => {
              console.log("PP CLICKED!");
            }}
            ref={ppButtonLottieRef}
            src="/lotties/pp-button.lottie"
            background="transparent"
            speed="1"
            direction="1"
            mode="normal"
            className={styles.pp_button_lottie}
            loop
            autoplay
          ></DotLottiePlayer>
        </a>
      </div>
    </div>
  );
}