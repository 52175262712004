import styles from "./PopupModule.module.css";

export default function PopupModule({ type, onCloseActivePopup }) {
  const terms = (
    <div className={styles.overlay_terms}>
      <span
        className={styles.close}
        onClick={() => {
          onCloseActivePopup();
        }}
      >
        &times;
      </span>
      <h1>Terms of Service</h1>
      <p>
        Welcome to Aristocat Studio's Terms of Service. These terms outline the
        rules and regulations for the use of our design studio's website and
        services.
      </p>
      <p>
        By accessing this website and utilizing our services, we assume you
        accept these terms and conditions. Do not continue to use Aristocat
        Studio if you do not agree to all of the terms and conditions stated on
        this page.
      </p>

      <h2>1. Services</h2>
      <p>
        Aristocat Studio provides design services including but not limited to
        graphic design, web design, branding, and illustration. All services are
        provided based on the specifications and requirements agreed upon
        between Aristocat Studio and the client.
      </p>

      <h2>2. Payment</h2>
      <p>
        Payment terms will be outlined in the project proposal or contract
        provided to the client. Payment for services rendered is due according
        to the agreed-upon schedule. Failure to make payment may result in
        project delays or termination.
      </p>

      <h2>3. Intellectual Property</h2>
      <p>
        Aristocat Studio retains ownership of all intellectual property rights
        related to the services provided, including but not limited to designs,
        concepts, and ideas, unless otherwise specified in writing.
      </p>

      <h2>4. Client Responsibilities</h2>
      <p>
        Clients are responsible for providing clear and timely feedback, as well
        as any materials or information necessary for the completion of the
        project. Delays caused by the client may result in adjustments to the
        project timeline and additional fees.
      </p>

      <h2>5. Revisions</h2>
      <p>
        A certain number of revisions may be included in the project scope as
        outlined in the proposal or contract. Additional revisions may incur
        additional fees.
      </p>

      <h2>6. Confidentiality</h2>
      <p>
        Aristocat Studio agrees to keep all client information confidential and
        will not disclose any sensitive information to third parties without
        prior consent.
      </p>

      <h2>7. Termination</h2>
      <p>
        Either party may terminate the agreement if the other party fails to
        fulfill its obligations. Termination may result in fees for work
        completed up to the termination date.
      </p>

      <h2>8. Limitation of Liability</h2>
      <p>
        Aristocat Studio will not be liable for any direct, indirect,
        incidental, special, or consequential damages resulting from the use or
        inability to use our services.
      </p>

      <h2>9. Governing Law</h2>
      <p>
        These terms and conditions are governed by and construed in accordance
        with the laws of India and any disputes relating to these terms and
        conditions will be subject to the exclusive jurisdiction of the courts
        of India.
      </p>

      <h2>10. Changes to Terms</h2>
      <p>
        Aristocat Studio reserves the right to modify these terms of service at
        any time. Changes will be effective immediately upon posting to the
        website. Continued use of our services after any such changes shall
        constitute your consent to such changes.
      </p>

      <p>
        By using Aristocat Studio's services, you agree to abide by these terms
        and conditions. If you have any questions or concerns, please contact us
        at hello@aristocatstudio.com.
      </p>

      <p>Last updated: 19 February 2024</p>

      <h4>Aristocat Studio Private Limited</h4>
    </div>
  );

  const privacy = (
    <div className={styles.overlay_privacy}>
      <span
        className={styles.close}
        onClick={() => {
          onCloseActivePopup();
        }}
      >
        &times;
      </span>
      <h1>Privacy Policy</h1>
      <p>
        Your privacy is important to us. It is Aristocat Studio's policy to
        respect your privacy regarding any information we may collect from you
        across our website, aristocatstudio.com, and other sites we own and
        operate.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        We only collect information about you if we have a reason to do so — for
        example, to provide our services, to communicate with you, or to make
        our services better.
      </p>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect in various ways, including to:</p>
      <ul>
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>
          Communicate with you, either directly or through one of our partners,
          including for customer service, to provide you with updates and other
          information relating to the website, and for marketing and promotional
          purposes
        </li>
        <li>Send you emails</li>
        <li>Find and prevent fraud</li>
      </ul>

      <h2>3. Log Data</h2>
      <p>
        Like many website operators, we collect information that your browser
        sends whenever you visit our website (“Log Data”). This Log Data may
        include information such as your computer's Internet Protocol (“IP”)
        address, browser type, browser version, the pages of our website that
        you visit, the time and date of your visit, the time spent on those
        pages, and other statistics.
      </p>

      <h2>4. Cookies</h2>
      <p>
        Cookies are files with a small amount of data, which may include an
        anonymous unique identifier. Cookies are sent to your browser from a
        website and stored on your computer's hard drive.
      </p>
      <p>
        We use "cookies" to collect information. You can instruct your browser
        to refuse all cookies or to indicate when a cookie is being sent.
        However, if you do not accept cookies, you may not be able to use some
        portions of our website.
      </p>

      <h2>5. Third-Party Services</h2>
      <p>
        We may employ third-party companies and individuals due to the following
        reasons:
      </p>
      <ul>
        <li>To facilitate our website</li>
        <li>To provide the website on our behalf</li>
        <li>To perform website-related services</li>
        <li>To assist us in analyzing how our website is used</li>
      </ul>
      <p>
        We want to inform our website users that these third parties have access
        to your Personal Information. The reason is to perform the tasks
        assigned to them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.
      </p>

      <h2>6. Security</h2>
      <p>
        We value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security.
      </p>

      <h2>7. Links to Other Sites</h2>
      <p>
        Our website may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>

      <h2>8. Children's Privacy</h2>
      <p>
        Our website does not address anyone under the age of 13. We do not
        knowingly collect personal identifiable information from children under
        13. In the case we discover that a child under 13 has provided us with
        personal information, we immediately delete this from our servers. If
        you are a parent or guardian and you are aware that your child has
        provided us with personal information, please contact us so that we will
        be able to take the necessary actions.
      </p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. Thus, we advise you
        to review this page periodically for any changes. We will notify you of
        any changes by posting the new Privacy Policy on this page. These
        changes are effective immediately after they are posted on this page.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us at hello@aristocatstudio.com.
      </p>

      <p>Last updated: 19 February 2024</p>

      <h4>Aristocat Studio Private Limited</h4>
    </div>
  );

  const faq = (
    <div className={styles.overlay_faq}>
      <span
        className={styles.close}
        onClick={() => {
          onCloseActivePopup();
        }}
      >
        &times;
      </span>
      <h1>FAQ</h1>
      <h2>1. What is Aristocat studio?</h2>
      <p>
        Aristocat Studio is a visual design growth agency that strives to be
        your one stop destination for your needs. We do web design and
        development, content and copywriting, branding work, explainer videos,
        custom illustrations and animations, Lottie animations and so much more.
        Basically, everything you need to get your idea off the ground and in
        front of your target audience.
      </p>

      <h2>
        2. If I have an existing team, can we collaborate together on our projects?
      </h2>
      <p>
        Absolutely! We believe that all great works of art are a result of
        stellar people coming together and sharing their unique ideas.
      </p>
      <h2>3. Can I see some samples of your work?</h2>
      <p>
        Sure! Head over to our homepage and click on the portfolio button or
        highlight under each section.
      </p>

      <h2>4. Can I get a refund if I don’t like your work?</h2>
      <p>
        It’s unlikely that you won’t like our work because we like to keep our
        clients updated every step of the way about the work-in-progress. If you
        don’t like anything, we’ll make the necessary changes and if you think
        we aren’t the right fit for your job, we’ll send you a full refund. But,
        no refunds will be granted if we’ve already done the work.
      </p>

      <h2>5. What is your area of expertise?</h2>
      <p>
        Currently, we have a team of 15 unique individuals who specialize in
        many different areas such as illustration, graphics design, branding,
        logo and website animations, explainer videos, content and copywriting,
        web development and design. We like to believe we do a good job at all
        of them!
      </p>

      <h2>6. At what stage of my business journey can I approach you?</h2>
      <p>At any stage you want!</p>
      <ul>
        <li>
          If you’re thinking of getting your business off the ground and need a
          partner to help you in designing a website, logo and other branding or
          marketing material.
        </li>
        <li>
          If your business is already active and needs help in reaching more
          people through social media posts or animated explainer videos.
        </li>
        <li>
          Or you need SEO-optimized website content and copy to grab more
          eyeballs and make more sales.
        </li>
        <li>
          Or you just need a helping hand here and there either for yourself or
          for your client projects.
        </li>
        <li>
          We’re here whenever you need. Just shoot us an email or use the
          contact form and we’ll get back right back at ya!
        </li>
      </ul>
    </div>
  );

  if (type === "terms") {
    return terms;
  } else if (type === "privacy") {
    return privacy;
  } else {
    return faq;
  }
}
