import styles from "./Floor.module.css";
import { DotLottiePlayer, PlayerEvents } from "@dotlottie/react-player";
import { useCallback, useEffect, useRef } from "react";

export default function Floor({ name, onActivePortfolio, currentPortfolio }) {
  const floorName = `${name}_floor`;
  const floorLottieName = `${name}_lottie`;
  const doorName = `${name}_lottie`;
  const hiddenDivName = `${name}_hidden_div`;

  const containerRef = useRef(null);
  const floorLottieRef = useRef(null);
  const doorLottieRef = useRef(null);

  const isInView = useRef(false);
  const floorIsReady = useRef(false);
  const doorIsReady = useRef(false);

  useEffect(() => {
    if (currentPortfolio) {
      floorLottieRef.current?.stop();
      if (doorLottieRef.current.direction === 1) {
        doorLottieRef.current?.setDirection(-1);
        doorLottieRef.current?.play();
      }
    } else {
      if (floorIsReady.current && doorIsReady.current) {
        floorLottieRef.current?.play();
        doorLottieRef.current?.setDirection(1);
        doorLottieRef.current?.play();
      }
    }
  }, [currentPortfolio]);

  const checkInView = useCallback(() => {
    if (floorIsReady.current && doorIsReady.current) {
      const rect = containerRef.current.getBoundingClientRect();
      isInView.current = rect.top < window.innerHeight && rect.bottom >= 0;
      if (isInView.current  && !currentPortfolio) {
        floorLottieRef.current?.play();
        doorLottieRef.current?.play();
      } else {
        floorLottieRef.current?.stop();
        doorLottieRef.current?.stop();
      }
    }
  }, [doorIsReady, floorIsReady, isInView, currentPortfolio]);

  useEffect(() => {
    document.addEventListener("scroll", checkInView);
    return () => {
      document.removeEventListener("scroll", checkInView);
    };
  }, [checkInView]);

  return (
    <div ref={containerRef} className={`${styles[floorName]}`}>
      <img
        src={`./pics/${name}-floor-bg.png`}
        alt="floor-bg"
        className={styles.floor_bg}
      />
      <DotLottiePlayer
        ref={floorLottieRef}
        src={`./lotties/${name}-floor.lottie`}
        background="transparent"
        speed="1"
        direction="1"
        mode="normal"
        className={`${styles[floorLottieName]}`}
        loop
        onEvent={(event) => {
          // Listen for player ready
          if (event === PlayerEvents.Ready) {
            floorIsReady.current = true;
          }
        }}
      ></DotLottiePlayer>

      <DotLottiePlayer
        ref={doorLottieRef}
        src={`./lotties/${name}-door-hold.lottie`}
        background="transparent"
        speed="1"
        direction="1"
        mode="normal"
        className={`${styles[doorName]}`}
        onEvent={(event) => {
          // Listen for player ready
          if (event === PlayerEvents.Ready) {
            doorIsReady.current = true;
          }
        }}
      ></DotLottiePlayer>

      <div
        className={`${styles[hiddenDivName]}`}
        onClick={() => {
          onActivePortfolio(name);
        }}
      ></div>
    </div>
  );
}
