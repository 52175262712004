import { useState, useEffect } from "react";
import { DotLottiePlayer, PlayerEvents } from "@dotlottie/react-player";
import styles from "./MTTLoadingScreen.module.css";

export default function MTTLoadingScreen({ onLoading, onBarIsReady }) {
  const [welcomeClicked, setWelcomeClicked] = useState(false);
  const [logoIsReady, setLogoIsReady] = useState(false);

  useEffect(() => {
    document.body.classList.add("no-scroll");
  }, []);

  return (
    <div
      className={`${styles.loading_container} ${
        welcomeClicked && styles.invisible
      }`}
    >
      <div className={styles.logo_container}>
        <DotLottiePlayer
        className={styles.logo_lottie}
          src="./lotties/mtt-loading.lottie"
          background="transparent"
          speed="1"
          direction="1"
          mode="normal"
          loop
          autoplay
          onEvent={(event) => {
            if(event === PlayerEvents.Ready) {
                setLogoIsReady(true);
            }
          }}
        ></DotLottiePlayer>
      </div>
      <div className={`${styles.text} ${logoIsReady && styles.loaded}`}>
        Learn more about the team by clicking the characters.
      </div>

      <div className={styles.welcome_container}>
        {(!onLoading && onBarIsReady) && (
          <div
            className={`${styles.welcome_button}`}
            onClick={() => {
              setWelcomeClicked(true);
              document.body.classList.remove("no-scroll");
            }}
          >
            Meet Our Team
          </div>
        )}
      </div>
    </div>
  );
}
